<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <input
      v-tooltip
      autocomplete="off"
      :title="searchFilter"
      class="dropdown-input"
      :name="select_value"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
    />

    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="option in filteredOptions"
        :key="option.uuid"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  template: "Dropdown",
  props: {
    value: {
      type: [Number, String, Object],
      default: () => {
        return "";
      },
    },
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
      note: "Options of dropdown. An array of options with id and name",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      default: 0,
      note: "Max items showing",
    },
    clear: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      selected: "",
      optionsShown: false,
      searchFilter: "",
    };
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      // const regOption = new RegExp(this.searchFilter.text, "ig");
      for (const option of this.options) {
        if (
          this.searchFilter?.length < 1 ||
          String(option.text)
            .toLocaleLowerCase()
            .includes(String(this.searchFilter).toLocaleLowerCase()) ||
          String(option.employee_id)
            .toLocaleLowerCase()
            .includes(String(this.searchFilter).toLocaleLowerCase())
        ) {
          if (this.maxItem) {
            if (filtered.length < this.maxItem) filtered.push(option);
          } else {
            filtered.push(option);
          }
        }
      }
      return filtered;
    },
    select_value() {
      if (this.value) {
        let selected = this.options.find((item) => item.value == this.value);
        return this.selectedOption(selected);
      }
      return this.clear_field();
    },
  },
  methods: {
    selectedOption(option) {
      this.selected = option?.value;
      this.searchFilter = option?.text;
    },
    selectOption(option) {
      this.selected = option.value;
      this.optionsShown = false;
      this.searchFilter = option.text;
      this.$emit("selected", this.selected);
    },
    clear_field() {
      this.searchFilter = "";
      this.selected = "";
      this.$emit("reset");
    },
    validate() {
      if (this.disabled) return { valid: true, error: "" };
      let fn = true;
      for (let key in this.rules) {
        let res = this.rules[key](this.selected);
        if (typeof res === "string" || !res) {
          fn = res;
          this.invalid = true;
          break;
        }
      }
      if (typeof fn === "string") return { valid: false, error: fn };
      else if (fn) {
        this.invalid = false;
        return { valid: true, error: "" };
      } else return { valid: false, error: "" };
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    exit() {
      if (this.selected == "" || this.selected.value) {
        this.selected = "";
        this.searchFilter = "";
      }
      this.$emit("selected", this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = "";
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    },
    clear() {
      if (this.clear) {
        this.searchFilter = "";
        this.selected = "";
        this.$emit("reset");
      }
    },
  },
  directives: {
    tooltip: {
      bind: function (el) {
        el.addEventListener("mouseover", function (evt) {
          let targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            targetEl.setAttribute("title", targetEl.value);
          } else {
            targetEl.hasAttribute("title") && targetEl.removeAttribute("title");
          }
        });
      },
    },
  },
};
</script>

<style scoped>
.dropdown-input {
  font-family: Effra, sans-serif;
  padding: 0 40px 0 14px;
  position: relative;
  width: calc(100% - 55px);
  height: 44px;
  min-height: 44px;
  margin-top: 5px;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1f2120;
  border: 1px #c4c4c4 solid;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;

  background: url("../../../src/assets/select_arrow.svg") #f9f9f9 no-repeat
    right 18px center;
}

.dropdown-input:hover:enabled {
  cursor: pointer;
  background-color: #e5e5e5;
}

.dropdown-input:disabled {
  background-color: #d0d0d0;
}
.dropdown-input::placeholder {
  color: #dddddd;
  opacity: 1;
}
.dropdown-input:disabled::placeholder {
  color: #6d6d6d;
  opacity: 1;
}
.dropdown {
  margin: 0;
  padding: 0;
  color: #575858;
  position: relative;
  display: block;
}
.dropdown:hover .dropdowncontent {
  display: block;
}

.dropdown-content {
  position: absolute;
  display: block;
  width: 338px;
  margin-top: 8px;
  z-index: 100;
  cursor: pointer;
  background-color: #e5e5e5;
  box-shadow: 0 1px 4px rgba(39, 87, 255, 0.1), 0 2px 8px rgba(39, 87, 255, 0.5);
  border-radius: 8px;
  overflow-y: overlay;
  scrollbar-color: #c4c4c4cc transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  max-height: 150px;
}

.dropdown-content::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #c4c4c4cc;
  border-radius: 40px;
  width: 8px;
}
.dropdown-content::-webkit-scrollbar-track {
  background: transparent;
}

.dropdown-item {
  width: 512px;
  padding-left: 16px;
  height: 40px;
  line-height: 40px;

  display: flex;
  align-items: center;
  background-color: white;
}

.dropdown-item:hover {
  border-radius: 0 0 8px 8px;
  background-color: #e5e5e5;
  outline: solid 1px #e5e5e5;
}
</style>
