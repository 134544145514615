<template>
  <div class="confirm-dialog" v-if="enable">
    <div class="confirm-dialog__window" :style="win_height">
      <img
        class="confirm-dialog__icon"
        src="@/assets/close.svg"
        alt="X"
        @click="$emit('cancel')"
      />
      <img class="confirm-dialog__img" :src="img_path" alt="img" />
      <span class="confirm-dialog__text" :style="width">
        {{ text }}
      </span>
      <span
        class="confirm-dialog__text confirm-dialog__text--error"
        v-if="error_text"
        :style="width"
      >
        {{ error_text }}
      </span>
      <div class="confirm-dialog__buttons-row">
        <custom-btn
          class="button button_theme_blue"
          @click="$emit('confirm')"
          >{{ button_text }}</custom-btn
        >
        <custom-btn
          v-if="cancel_text"
          class="button"
          @click="$emit('cancel')"
          >{{ cancel_text }}</custom-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
export default {
  name: "confirmDialog",
  props: [
    "src",
    "text",
    "error_text",
    "button_text",
    "cancel_text",
    "height",
    "text_width",
    "enable",
  ],
  components: { CustomBtn },
  watch: {
    enable(val) {
      if (val) {
        document.body.style.overflow = "hidden";
        if (
          document.documentElement.clientHeight <
          document.documentElement.scrollHeight
        ) {
          document.body.style.paddingRight = "17px";
        }
      } else {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0";
      }
    },
  },
  computed: {
    img_path() {
      return require(`@/assets/${this.src}`);
    },
    win_height() {
      return { height: this.height + "px" };
    },
    width() {
      return { width: this.text_width + "px" };
    },
  },
};
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-dialog__window {
  width: 400px;
  background-color: white;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  border-radius: 16px;
  box-shadow: 0 14px 44px rgba(91, 107, 168, 0.3);
}
.confirm-dialog__icon {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.confirm-dialog__icon:hover {
  filter: invert(75%);
}
.confirm-dialog__img {
  margin-top: 24px;
}
.confirm-dialog__text {
  flex-grow: 1;
  padding: 20px;
}
.confirm-dialog__buttons-row {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.confirm-dialog__text--error {
  color: red;
}
</style>
