<template>
  <div class="battery_level">
    <span>{{ $t("components.battery_level.title") }}</span>
    <popup-hint class="hint-icon">{{
      $t("components.battery_level.hint")
    }}</popup-hint>
    <custom-number-field
      ref="field"
      v-model="battery"
      min="25"
      max="100"
      class="battery-level__input"
      @change="$emit('input', battery)"
    ></custom-number-field>
    <span class="battery_level__percent">%</span>
    <span class="battery_level__info" :class="{ error: battery_info.error }">{{
      battery_info.text
    }}</span>
  </div>
</template>

<script>
import PopupHint from "@/components/recurringComponents/popupHint";
import CustomNumberField from "@/components/recurringComponents/customNumberField";
export default {
  name: "batteryLevel",
  props: {
    value: Number,
    battery_info: {
      error: Boolean,
      text: String,
    },
  },
  components: { CustomNumberField, PopupHint },
  data() {
    return {
      battery: this.value,
    };
  },
  methods: {
    validate() {
      let valid = this.$refs.field.validate();
      if (valid.valid) {
        return { valid: true, error: "" };
      } else {
        return { valid: false, error: "no_value" };
      }
    },
  },
};
</script>

<style scoped></style>
