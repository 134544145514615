<template>
  <div class="text-fields-block__row">
    <div class="text-fields-block__element text-fields-block__element_hubs">
      <span :class="{ invalid_field: this.invalid.id }">{{
        $t("hubs.hub_id")
      }}</span>
      <custom-text-field
        ref="id"
        :rules="[rules.require, rules.is_mac]"
        class="text-fields-block__field"
        v-model="change_hub.hub_id"
        @change="refresh"
        :placeholder="$t('hubs.hub_id_hint')"
        :disabled="for_table"
      ></custom-text-field>
    </div>
    <div class="text-fields-block__element text-fields-block__element_hubs">
      <span :class="{ invalid_field: this.invalid.city_id }">{{
        $t("hubs.city")
      }}</span>
      <autocomplete
        v-on:selected="update_city_select"
        :value="change_hub.city_id"
        :rules="[rules.require]"
        id="city_select"
        class="text-fields-block__field"
        ref="city_id"
        :options="cities"
        :clear="clear"
        v-on:reset="reset_clear"
        :placeholder="city_select_placeholder"
      ></autocomplete>
    </div>
    <div class="text-fields-block__element text-fields-block__element_hubs">
      <span :class="{ invalid_field: this.invalid.pos_id }">{{
        $t("hubs.pos")
      }}</span>
      <autocomplete
        v-on:selected="update_pos_select"
        :value="change_hub.pos_id"
        :rules="[rules.require]"
        id="pos_select"
        class="text-fields-block__field"
        ref="pos_id"
        :options="pos"
        :clear="clear"
        :disabled="!change_hub.city_id || pos.length === 0"
        v-on:reset="reset_clear"
        :placeholder="$t('hubs.choose_pos')"
      ></autocomplete>
    </div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";
import Autocomplete from "@/components/recurringComponents/autocomplete";
import rules from "@/rules";

export default {
  name: "hubsTextFields",
  props: {
    value: {
      hub_id: String,
      pos_id: Number,
      city_id: Number,
      address: String,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    for_table: Boolean,
  },
  components: { CustomTextField, Autocomplete },
  data() {
    return {
      // change_hub: this.value,
      rules: rules,
      city_select_placeholder: "",
      invalid: {
        id: false,
        pos_id: false,
        city_id: false,
        address: false,
      },
      clear: false,
    };
  },
  created() {
    this.$store.dispatch("load_cities").then((response) => {
      const { data } = response.data;
      this.render_city_select_placeholder(data);
    });
    if (this.change_hub.city_id) {
      this.$store.dispatch("load_pos", this.change_hub.city_id);
    }
  },
  computed: {
    change_hub() {
      return this.value;
    },
    cities() {
      return this.$store.getters.CITIES.map((city) => {
        return {
          value: city.id,
          uuid: city.id,
          text: `${city.name}`,
        };
      });
    },
    pos() {
      return this.$store.getters.POS.map((pos) => {
        return {
          value: pos.id,
          uuid: pos.id,
          address: pos.address,
          text: `${pos.name}`,
        };
      });
    },
  },
  methods: {
    render_city_select_placeholder(data) {
      if (data.length === 0) {
        this.invalid.city_id = true;
        this.city_select_placeholder = this.$i18n.t("hubs.empty_city");
      } else {
        this.invalid.city_id = false;
        this.city_select_placeholder = this.$i18n.t("hubs.choose_city");
      }
    },
    reset_clear() {
      this.clear = false;
    },
    hub_exist() {
      return false;
    },
    refresh() {
      this.$emit("change", { value: this.change_hub });
    },
    update_city_select(value) {
      this.clear_pos_fields();
      this.change_hub.city_id = value;
      this.error_text = "";
      if (value) {
        this.$store.dispatch("load_pos", value);
      }
    },
    update_pos_select(value) {
      this.change_hub.pos_id = value;
      if (value) {
        this.change_hub.pos_address = this.pos.find(
          (x) => x.value == value
        ).address;
      } else {
        this.change_hub.pos_address = "";
      }
      this.error_text = "";
    },
    clear_pos_fields() {
      this.change_hub.pos_id = "";
      this.change_hub.pos_address = "";
    },
    validate() {
      let error = false;
      let error_type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        this._data["invalid"][key] = !valid.valid;
        if (!valid.valid) {
          error_type = valid.error;
          error = true;
        }
      }

      if (!this.for_table && this.hub_exist()) {
        return { valid: false, error: "user_exists" };
      } else return { valid: !error, error: error_type };
    },
  },
};
</script>

<style scoped>
.text-fields-block__element_hubs {
  width: 340px;
}
</style>
