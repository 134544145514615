<template>
  <div class="check-block">
    <div class="check-block__title">
      <div class="check-block__title-text">
        {{ title }}<popup-hint v-if="hint">{{ hint_text }}</popup-hint>
      </div>
      <span class="check-block__info" :class="{ error: error }">{{
        error_text
      }}</span>
    </div>
    <div class="check-block__row check-block__row_with-time">
      <label
        ><input
          class="check-block__input"
          v-model="val"
          value="on"
          type="radio"
          :name="id"
        /><span class="check-block__text">{{ on_text }}</span></label
      >
    </div>
    <div class="check-block__row">
      <label
        ><input
          class="check-block__input"
          v-model="val"
          value="off"
          type="radio"
          :name="id"
        /><span class="check-block__text check-block__text_with-time">{{
          off_text
        }}</span></label
      >
      <custom-time-field
        ref="time"
        v-model="times"
        class="check-block__time"
        :disabled="value"
        @input="update_upload"
      ></custom-time-field>
    </div>
  </div>
</template>

<script>
import CustomTimeField from "@/components/recurringComponents/customTimeField";
import PopupHint from "@/components/recurringComponents/popupHint";
export default {
  name: "checkBlockWithTime",
  props: {
    value: Boolean,
    id: String,
    title: String,
    on_text: String,
    off_text: String,
    hint: Boolean,
    hint_text: String,
    error: Boolean,
    error_text: String,
    time: String,
  },
  components: { PopupHint, CustomTimeField },
  data() {
    return {
      times: this.time,
      on: this.value,
    };
  },
  computed: {
    val: {
      get() {
        return this.on ? "on" : "off";
      },
      set(val) {
        val === "on" ? (this.on = true) : (this.on = false);
        this.$emit("input", this.on);
        this.$emit("change");
      },
    },
  },
  methods: {
    update_upload() {
      if (this.value && this.times === "HH:mm") {
        return;
      }
      if (this.value) {
        this.times = "HH:mm";
      }
      this.$emit("update_time", this.times);
    },
    validate() {
      let valid = this.$refs.time.validate();
      return { valid: valid.valid, error: valid.error };
    },
  },
};
</script>

<style scoped></style>
