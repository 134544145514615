<template>
  <div
    class="input-field input-field_number"
    :class="{
      // 'input-field_disabled': disabled,
      'input-field_focused': focused,
    }"
    @focusin="focused = true"
    @focusout="focused = false"
  >
    <div class="input-field__input-wrapper">
      <label class="input-field__label">
        <input
          id="input_num"
          class="input-field__input"
          :value="value"
          type="number"
          :min="min"
          :max="max"
          pattern="\d*"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="this.check_number"
        />
      </label>
    </div>
    <div class="input-field__extra-btn">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "customNumberField",
  props: ["value", "placeholder", "disabled", "min", "max", "rules"],
  data() {
    return {
      invalid: false,
      focused: false,
    };
  },
  methods: {
    check_number(event) {
      if (Number(event.target.value) > Number(event.target.max))
        event.target.value = Number(event.target.max);

      this.$emit("input", Number(event.target.value));
      this.$emit("change");
      this.$nextTick(this.validate);
    },
    validate() {
      if (this.disabled) return { valid: true, error: "" };

      let fn = true;
      for (let key in this.rules) {
        let res = this.rules[key](this.value);
        if (typeof res === "string" || !res) {
          fn = res;
          this.invalid = true;
          break;
        }
      }
      if (typeof fn === "string") return { valid: false, error: fn };
      else if (fn) {
        if (
          Number(this.value) >= Number(this.min) &&
          Number(this.value) <= Number(this.max)
        ) {
          this.invalid = false;
          return { valid: true, error: "" };
        } else {
          this.invalid = true;
          return { valid: false, error: "min-max" };
        }
      } else return { valid: false, error: "" };
    },
  },
};
</script>

<style scoped></style>
