var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__header"},[_vm._l((_vm.header_items),function(item){return _c('div',{key:item.name,staticClass:"table__cell table__header-item",class:item.name,style:(item.name === _vm.active_name ? _vm.active_style : ''),on:{"click":function($event){_vm.$emit('change_sorted', item.name);
        _vm.set_active_style(item.name);}}},[_c('span',{staticClass:"table__header-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"table__header-arrows"},[_c('div',{staticClass:"table__header-arrow table__header-top-arrow",class:{
            'table__header-arrow_pressed': item.sorted === 1,
          }}),_c('div',{staticClass:"table__header-arrow table__header-bottom-arrow",class:{
            'table__header-arrow_pressed': item.sorted === -1,
          }})])])}),(
        _vm.$auth.check({ permissions: 'add_hubs' }) ||
        _vm.$auth.check({ permissions: 'hub_settings' }) ||
        _vm.$auth.check({ name: 'admin' })
      )?_c('div',{staticClass:"table__cell table__header-item hubs-table__img-header"}):_vm._e()],2),_c('div',{staticClass:"table__data"},[_vm._l((_vm.items),function(item){return [_c('div',{key:item.hub_id,staticClass:"table__row"},[(_vm.show_edit(item))?_c('div',{staticClass:"table-2__cells"},[_vm._l((Object.keys(item)),function(cell){return [(_vm.show_cell(cell))?_c('div',{key:cell,staticClass:"table__cell",class:cell,style:(cell === _vm.active_name ? _vm.active_style : ''),on:{"click":function($event){return _vm.set_active_style(cell)}}},[_c('div',{staticClass:"table__cell-text"},[_vm._v(_vm._s(item[cell]))])]):_vm._e()]}),(
              _vm.$auth.check({ permissions: 'add_hubs' }) ||
              _vm.$auth.check({ permissions: 'hub_settings' }) ||
              _vm.$auth.check({ name: 'admin' })
            )?_c('div',{staticClass:"table__cell table-2__img-cell hubs-table__img-cell"},[(
                _vm.$auth.check({ permissions: 'hub_settings' }) ||
                _vm.$auth.check({ name: 'admin' })
              )?_c('edit-btn',{on:{"click":function($event){return _vm.set_show_change_item(item)}}}):_vm._e(),(
                _vm.$auth.check({ permissions: 'add_hubs' }) ||
                _vm.$auth.check({ name: 'admin' })
              )?_c('delete-btn',{on:{"click":function($event){return _vm.call_confirm_dialog(item)}}}):_vm._e()],1):_vm._e()],2):_c('div',{staticClass:"table-2__change-items"},[_c('hubs-change-block',{attrs:{"value":item},on:{"cancel":_vm.close_change_block,"delete":function($event){return _vm.call_confirm_dialog(item)}}})],1)])]})],2),_c('confirm',{attrs:{"enable":_vm.show_confirm,"src":"confirm_delete.svg","button_text":_vm.$t('hubs.confirm_button'),"text":_vm.$t('hubs.confirm_text'),"text_width":"200"},on:{"cancel":_vm.close_confirm_dialog,"confirm":_vm.confirm_delete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }