<template>
  <div
    class="input-field"
    :class="{
      'input-field_disabled': disabled,
      'input-field_focused': focused,
    }"
    @focusin="
      focused = true;
      $emit('focusin');
    "
    @focusout="
      focused = false;
      $emit('focusout');
    "
  >
    <label class="input-field__label">
      <input
        class="input-field__input"
        v-model="text"
        :maxlength="max"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <div class="input-field__extra-btn">
        <slot />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "customTextField",
  props: ["value", "placeholder", "disabled", "rules", "max"],
  data() {
    return {
      invalid: false,
      focused: false,
    };
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change");
        this.$nextTick(this.validate);
      },
    },
  },
  methods: {
    validate() {
      if (this.disabled) return { valid: true, error: "" };
      let fn = true;
      for (let key in this.rules) {
        let res = this.rules[key](this.text);
        if (typeof res === "string" || !res) {
          fn = res;
          this.invalid = true;
          break;
        }
      }
      if (typeof fn === "string") return { valid: false, error: fn };
      else if (fn) {
        this.invalid = false;
        return { valid: true, error: "" };
      } else return { valid: false, error: "" };
    },
  },
};
</script>

<style scoped>
.input-field__input:disabled {
  background-color: #d0d0d0;
}
</style>
