<template>
  <div
    class="popup-hint__icon"
    @mouseenter="show_window = true"
    @mouseleave="show_window = false"
  >
    <div v-if="show_window" class="popup-hint__text"><slot /></div>
  </div>
</template>

<script>
export default {
  name: "popupHint",
  data() {
    return {
      show_window: false,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
