<template>
  <div class="table-2__img-wrapper">
    <div
      class="table-2__img table-2__img_edit"
      @click="$emit('click')"
      @mouseenter="show_window = true"
      @mouseleave="show_window = false"
    ></div>
    <div v-if="show_window" class="popup-hint__text popup-hint__text_table-img">
      {{ $t("components.table_btn.edit") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "editBtn",
  data() {
    return {
      show_window: false,
    };
  },
};
</script>

<style scoped></style>
