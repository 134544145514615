<template>
  <div class="change-block">
    <hub-fields
      ref="fields"
      class="change-block__text-fields"
      :edit="true"
      v-model="change_item"
      :for_table="true"
    ></hub-fields>
    <div class="change-block__check-row">
      <check-block
        class="change-block__check-element"
        v-model="change_item.recording"
        :title="$t('hubs.change_block.recording_mode')"
        :on_text="$t('hubs.change_block.continuous_recording')"
        :off_text="$t('hubs.change_block.with_button_recording')"
        :hint="false"
        :error="record_info.error"
        :error_text="record_info.text"
      ></check-block>
      <check-block-with-time
        ref="time"
        class="change-block__check-element"
        v-model="change_item.upload_mode"
        v-on:update_time="update_time"
        :title="$t('hubs.change_block.upload_mode')"
        :on_text="$t('hubs.change_block.immediate_upload')"
        :off_text="$t('hubs.change_block.postponed_upload')"
        :hint="true"
        :hint_text="$t('hubs.change_block.hints.upload')"
        :error="upload_info.error"
        :error_text="upload_info.text"
        :time="change_item.upload_time"
      >
      </check-block-with-time>
      <check-block
        class="change-block__check-element"
        v-model="change_item.motion"
        :title="$t('hubs.change_block.motion_detection')"
        :on_text="$t('hubs.change_block.on')"
        :off_text="$t('hubs.change_block.off')"
        :hint="true"
        :hint_text="$t('hubs.change_block.hints.motion')"
        :error="motion_info.error"
        :error_text="motion_info.text"
      ></check-block>
    </div>
    <battery-level
      ref="battery"
      class="change-block__battery-level"
      v-model="change_item.battery"
      :battery_info="battery_info"
    ></battery-level>

    <div v-if="info.loader" class="change-block__btn-block">
      <div class="change-block__info-block">
        <loader></loader>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
    <div class="change-block__btn-block" v-else>
      <div class="change-block__info-block">
        <custom-btn class="button button_theme_blue" @click="edit_hub">{{
          $t("hubs.change_block.save")
        }}</custom-btn>
        <simple-button
          :title="$t('hubs.change_block.cancel')"
          @click="$emit('cancel')"
        ></simple-button>
        <span
          class="text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
      <custom-btn
        v-if="
          $auth.check({ permissions: 'add_hubs' }) ||
          $auth.check({ name: 'admin' })
        "
        class="change-block__delete-button button button_theme_delete"
        @click="$emit('delete')"
      >
        <span>{{ $t("hubs.change_block.delete") }}</span>
        <div class="change-block__delete-icon"></div>
      </custom-btn>
    </div>
  </div>
</template>

<script>
import HubFields from "@/components/components/hubsTextFields";
import CheckBlock from "@/components/recurringComponents/checkBlock";
import CheckBlockWithTime from "@/components/recurringComponents/checkBlockWithTime";
import BatteryLevel from "@/components/recurringComponents/batteryLevel";
import CustomBtn from "@/components/recurringComponents/customBtn";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import loader from "@/components/recurringComponents/loader";

export default {
  name: "hubsChangeBlock",
  props: ["value"],
  components: {
    SimpleButton,
    CustomBtn,
    BatteryLevel,
    CheckBlockWithTime,
    CheckBlock,
    HubFields,
    loader,
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
      change_item: Object.assign({}, this.value),
      record_info: {
        text: "",
        error: false,
      },
      upload_info: {
        text: "",
        error: false,
      },
      motion_info: {
        text: "",
        error: false,
      },
      battery_info: {
        text: "",
        error: false,
      },
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_value":
          return this.$i18n.t("errors.value");
        case "no_mac":
          return this.$i18n.t("hubs.loader.no_mac");
        case "progress":
          return this.$i18n.t("hubs.loader.progress_edit");
        case "failed":
          return this.$i18n.t("hubs.loader.failed_edit");
        case "success":
          return this.$i18n.t("hubs.loader.success_edit");
        default:
          return "";
      }
    },
  },
  methods: {
    update_time(value) {
      this.change_item.upload_time = value;
    },
    validate() {
      this.info.error = false;
      this.info.text = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    edit_hub() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        this.change_item.pos = { id: this.change_item.pos_id };
        this.change_item.city = { id: this.change_item.city_id };

        this.$store
          .dispatch("update_hub", this.change_item)
          .then(() => {
            this.info.type = "success";
          })
          .catch(() => {
            this.info.type = "failed";
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
