<template>
  <button
    @click="$emit('click')"
    :class="{ button_disabled: disabled }"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "customBtn",
  props: ["title", "disabled"],
};
</script>

<style scoped></style>
