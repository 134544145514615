<template>
  <div class="check-block">
    <div
      class="check-block__title"
      :class="{ 'check-block__title_small': small }"
    >
      <div class="check-block__title-text">
        {{ title
        }}<popup-hint class="hint-icon" v-if="hint">{{ hint_text }}</popup-hint>
      </div>
      <span class="check-block__info" :class="{ error: error }">{{
        error_text
      }}</span>
    </div>
    <div class="check-block__row" :class="{ 'check-block__row_small': small }">
      <label
        ><input
          class="check-block__input"
          v-model="val"
          value="on"
          type="radio"
          :name="id"
        /><span class="check-block__text">{{ on_text }}</span></label
      >
    </div>
    <div class="check-block__row check-block__row_last">
      <label
        ><input
          class="check-block__input"
          v-model="val"
          value="off"
          type="radio"
          :name="id"
        /><span class="check-block__text">{{ off_text }}</span></label
      >
    </div>
  </div>
</template>

<script>
import PopupHint from "@/components/recurringComponents/popupHint";
export default {
  name: "checkBlock",
  props: {
    value: Boolean,
    id: String,
    title: String,
    on_text: String,
    off_text: String,
    hint: Boolean,
    hint_text: String,
    error: Boolean,
    error_text: String,
    small: Boolean,
  },
  components: { PopupHint },
  data() {
    return {};
  },
  computed: {
    on: {
      get() {
        return this.value;
      },
      set(value) {
        console.log(value);
      },
    },
    val: {
      get() {
        return this.on ? "on" : "off";
      },
      set(val) {
        this.$emit("input", val === "on");
        this.$emit("change");
      },
    },
  },
};
</script>

<style scoped></style>
