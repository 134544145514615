<template>
  <div class="body">
    <div
      class="text-fields-block block-wide"
      v-if="
        $auth.check({ permissions: 'add_hubs' }) ||
        $auth.check({ name: 'admin' })
      "
    >
      <form @submit.prevent="confirm_add">
        <hubs-text-fields ref="add_hub" v-model="new_hub"></hubs-text-fields>
        <div class="text-fields-block__btn-row">
          <loader v-if="info.loader"></loader>
          <custom-btn
            class="button button_theme_blue"
            type="submit"
            :disabled="new_hub.id || !new_hub.city_id"
            v-else
            >{{ $t("hubs.add_btn") }}</custom-btn
          >
          <span
            v-if="empty_cities"
            class="text-fields-block__info-text text_color_red"
            >{{ $t("hubs.empty_cities") }}
            <span
              @click="goto_settings()"
              style="text-decoration: underline; cursor: pointer"
              >{{ $t("hubs.settings") }}</span
            ></span
          >
          <span
            class="text-fields-block__info-text text_color_green"
            :class="{ text_color_red: info.error }"
            >{{ info_text }}</span
          >
        </div>
      </form>
    </div>

    <div class="hubs__table block-wide">
      <hubs-table
        :header_items="headers"
        :items="hubs"
        @change_sorted="change_sorted"
      ></hubs-table>
    </div>

    <confirm-dialog
      :enable="show_confirm"
      src="confirm_reset.svg"
      :button_text="$t('hubs.confirm_add_button')"
      :text="$t('hubs.confirm_add_text')"
      text_width="300"
      @cancel="show_confirm = false"
      @confirm="add_hub"
    ></confirm-dialog>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import HubsTable from "@/components/components/hubsTable";
import HubsTextFields from "@/components/components/hubsTextFields";
import Loader from "@/components/recurringComponents/loader";
import ConfirmDialog from "@/components/recurringComponents/confirmDialog";

export default {
  name: "hubs",
  components: { ConfirmDialog, Loader, HubsTextFields, HubsTable, CustomBtn },
  data() {
    return {
      show_confirm: false,
      empty_cities: false,
      info: {
        error: false,
        type: "",
        loader: false,
      },
      new_hub: {
        hub_id: "",
        pos_id: "",
        city_id: "",
        address: "",
      },
      headers: [
        {
          name: "hub_id",
          title: this.$i18n.t("hubs.headers.hub_id"),
          sorted: 1,
        },
        {
          name: "local_ip",
          title: this.$i18n.t("hubs.headers.local_ip"),
          sorted: 0,
        },
        {
          name: "pos_name",
          title: this.$i18n.t("hubs.headers.pos"),
          sorted: 0,
        },
        {
          name: "city_name",
          title: this.$i18n.t("hubs.headers.city"),
          sorted: 0,
        },
        {
          name: "hub_status",
          title: this.$i18n.t("hubs.headers.hub_status"),
          sorted: 0,
        },
        {
          name: "badges",
          title: this.$i18n.t("hubs.headers.badges"),
          sorted: 0,
        },
        {
          name: "timezone",
          title: this.$i18n.t("hubs.headers.timezone"),
          sorted: 0,
        },
        {
          name: "fw_ver",
          title: this.$i18n.t("hubs.headers.fw_ver"),
          sorted: 0,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("load_hubs");
    this.check_empty_cities();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`hubs.headers.${item.name}`);
      }
    },
  },
  computed: {
    hubs() {
      return this.$store.getters.HUBS.map((hub) => {
        let upload_mode = hub.upload_mode === "instant",
          recording = hub.recording_mode === "continuous";
        return {
          id: hub.id,
          hub_id: hub.hub_id,
          local_ip: hub.ip,
          pos_id: hub.pos.id,
          pos: hub.pos,
          pos_name: hub.pos.name,
          city: hub.pos.city.id,
          city_id: hub.pos.city?.id,
          city_name: hub.pos.city?.name,
          address: hub.pos.address,
          hub_status: hub.status,
          badges: hub.badge_connected,
          timezone: "UTC " + hub.timezone,
          fw_ver: hub.firmware,
          upload_mode: upload_mode,
          motion: hub.motion_detection_state,
          recording: recording,
          upload_time: hub.upload_time ? hub.upload_time : ":",
          battery: hub.battery_ready_to_use,
        };
      });
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_mac":
          return this.$i18n.t("hubs.loader.no_mac");
        case "progress":
          return this.$i18n.t("hubs.loader.progress");
        case "failed":
          return this.$i18n.t("hubs.loader.failed");
        case "success":
          return this.$i18n.t("hubs.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    goto_settings() {
      this.$router.push("/settings");
    },
    check_empty_cities() {
      this.empty_cities = false;
      setTimeout(() => {
        if (this.$store.getters.CITIES.length === 0) {
          this.empty_cities = true;
        }
      }, 1000);
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.hubs.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    validate() {
      this.info.error = false;
      this.info.text = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
          this.reset_error_message();
        }
      }
      return !this.info.error;
    },
    add_hub() {
      if (this.validate()) {
        this.show_confirm = false;
        this.info.loader = true;
        this.info.type = "progress";
        delete this.new_hub.city_id;
        delete this.new_hub.pos_address;
        delete this.new_hub.address;
        this.$store
          .dispatch("add_hub", this.new_hub)
          .then(() => {
            this.info.type = "success";
            this.new_hub = {
              hub_id: "",
              pos_id: "",
              city_id: "",
              pos_address: "",
              address: "",
            };
          })
          .catch((err) => {
            this.info.error = true;
            this.info.type = err.response?.data.errors.find((x) =>
              x.message.includes("mac")
            )
              ? "no_mac"
              : "failed";
          })
          .finally(() => {
            this.info.loader = false;
            this.reset_error_message();
          });
      }
    },
    reset_error_message() {
      setTimeout(() => {
        this.info.type = "";
        this.info.error = false;
      }, [3000]);
    },
    confirm_add() {
      if (this.validate()) {
        this.show_confirm = true;
      }
    },
  },
};
</script>

<style scoped></style>
