<template>
  <div class="simple-button" @click="$emit('click')">{{ title }}</div>
</template>

<script>
export default {
  name: "simpleButton",
  props: ["title"],
};
</script>

<style scoped></style>
