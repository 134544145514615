let rules = {
  min_length_8: (val) => val.length >= 8 || "min_length_8",
  is_mail: (val) => {
    let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return reg.test(val) || "no_format";
  },
  min_one_letter: (val) => {
    let reg = /[a-zA-Zа-яА-Я]/;
    return reg.test(val) || "no_letters";
  },
  require: (val) => !!val || "required",
  valid_range: (val, val1) => val < val1 || "invalid_range",
  is_mac: (val) => {
    let reg =
      /^((([0-9A-F]{2}:){5})|(([0-9A-F]{2}-){5})|([0-9A-F]{10}))([0-9A-F]{2})$/i;
    return reg.test(val) || "no_mac";
  },
  max_length_50: (val) => val.length <= 50 || "no_format",
  password: (val) => {
    if (val) {
      let reg =
        /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
      return reg.test(val) || "no_password";
    } else return true;
  },
  is_phone: (val) => {
    // let reg = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    let reg = /^(\+7[0-9]{10})$/;
    return reg.test(val) || "no_phone";
  },
};

export default rules;
