<template>
  <span
    class="input-time"
    :class="{ 'input-time_disabled ': disabled, 'invalid-input': invalid }"
  >
    <label>
      <input
        class="input-time__input input-time__input_hour"
        type="number"
        max="23"
        min="00"
        v-model="hour_b"
        :placeholder="$t('general.time_hh')"
        :class="{ 'input-time_disabled': disabled }"
        :disabled="disabled"
        @input="this.check_input"
      />
    </label>
    <label>
      <input
        class="input-time__input input-time__input_minutes"
        type="number"
        max="59"
        min="00"
        v-model="minutes_b"
        :placeholder="$t('general.time_mm')"
        :class="{ 'input-time_disabled': disabled }"
        :disabled="disabled"
        @input="this.check_input"
      />
    </label>
  </span>
</template>

<script>
export default {
  name: "customTimeField",
  props: {
    value: {
      type: String,
      default: "HH:mm",
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invalid: false,
      error: {
        error: false,
        type: "no_format",
      },
      minutes_b: "mm",
      hour_b: "HH",
    };
  },
  created() {
    this.hour_b = this.value.split(":")[0] ? this.value.split(":")[0] : "HH";
    this.minutes_b = this.value.split(":")[1] ? this.value.split(":")[1] : "mm";
  },
  watch: {
    value(newVal) {
      this.hour_b = newVal.split(":")[0] ? newVal.split(":")[0] : "HH";
      this.minutes_b = newVal.split(":")[1] ? newVal.split(":")[1] : "mm";
    },
  },
  methods: {
    check_input(event) {
      if (Number(event.target.value) > Number(event.target.max)) {
        if (event.target.placeholder === "mm") {
          this.minutes_b = Number(event.target.max);
        } else {
          this.hour_b = Number(event.target.max);
        }
      }
      if (Number(event.target.value) < Number(event.target.min)) {
        if (event.target.placeholder === "mm") {
          this.minutes_b = Number(event.target.min);
        } else {
          this.hour_b = Number(event.target.min);
        }
      }
      this.update();
    },
    update() {
      this.invalid = false;
      this.error.error = false;
      let regtime = new RegExp(/\d{1,2}/, "g");
      if (this.hour_b !== "" && this.minutes_b !== "") {
        if (!String(this.hour_b).match(regtime)) {
          this.invalid = true;
          this.error.error = true;
        }
        if (!String(this.minutes_b).match(regtime)) {
          this.invalid = true;
          this.error.error = true;
        }
        this.$emit("input", this.hour_b + ":" + this.minutes_b);
      } else {
        this.invalid = true;
        this.error.error = true;
        this.$emit("input", "");
      }
      this.$emit("change");
    },
    validate() {
      if (!this.disabled) {
        this.update();
        if (this.error.error) {
          this.invalid = true;
          return { valid: false, error: this.error.type };
        }
      }
      this.invalid = false;
      return { valid: true, error: "" };
    },
  },
};
</script>

<style scoped></style>
