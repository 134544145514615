<template>
  <img src="@/assets/loader.svg" class="loader" alt="Loader" />
</template>

<script>
export default {
  name: "loader",
};
</script>

<style scoped></style>
